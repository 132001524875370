import React, {useState,useEffect} from 'react';

function Botones({setId,id,descripcion,tipo}) {

		const [nombre, setNombre] = useState(descripcion);

		const changeName = e =>{
			setNombre(e.target.value);
		}

	  	const[editando, setEditando] = useState(false);
	  	const[eliminando, setEliminando] = useState(false);

		const editar = () =>{
			console.log("editar")
	  		setEditando(!editando);
	  		setNombre(descripcion);
	  	}

	  	const eliminar = () =>{
			console.log("eliminar")
	  		setEliminando(!eliminando);
	  	}

	  	const setIdEditar = (id,desc) =>{
	  		setId([id,true,tipo,desc]);
	  	}

	  	const setIdEliminar = (id) =>{
	  		setId([id,false,tipo]);
	  	}

  		useEffect(() => { 
			console.log(nombre);
  		},[nombre]);

  		if(editando === true){
  			return (
  				<React.Fragment>
  					<p className="mb-0 pt-2">Nuevo nombre: <input type="text" className="form-control" onChange={changeName} name="nombre" value={nombre} /></p>
		  			<button className="btn btn-small btn-success" onClick={()=>setIdEditar(id,nombre)}>Aceptar</button> 
		  			<button className="btn btn-small btn-secondary" onClick={()=>editar()}>Cancelar</button>
  			  	</React.Fragment>);
  		}else if(eliminando === true){
  			return (
  				<React.Fragment>
  			  		<p className="mb-0 pt-2">¿Seguro que desea eliminar la opcion {descripcion}?</p>
  			  		<p><button className="btn btn-small btn-success" onClick={()=>setIdEliminar(id)}>Aceptar</button> 
  			  		<button className="btn btn-small btn-secondary" onClick={eliminar}>Cancelar</button></p>
  			  	</React.Fragment>);
  		}else{
  			return (
  				<p><button className="btn btn-small btn-primary" onClick={()=>editar("asd")}>Editar</button> 
  				<button className="btn btn-small btn-danger" onClick={eliminar}>Eliminar</button></p>);
  		}
}

export default Botones;