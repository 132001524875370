import React from 'react';

function Input(props) {

	const listItems = (props.options).map((opcion) =>
	<option key={opcion}>{opcion}</option>);

	return(
	    <div className={props.colSize ? "col-md-"+props.colSize : ""}>
		    <div className="form-group">
		        <label>{props.label}</label>
		        <select name={props.name} className="form-control" onChange={props.onChange}>
		        	{listItems}
		        </select>
		    </div>
	    </div>
	);
}

export default Input;
