import React, {useState} from 'react';

function NuevaCategoria({tipo,setAgregar}) {

		const [nombre, setNombre] = useState("");

		const changeName = e =>{
			setNombre(e.target.value);
		}

	  	const setIdAgregar = () =>{
	  		setAgregar([tipo,nombre]);
	  	}

		return (
			<React.Fragment>
				<p className="mb-0 pt-2">Agregar: <input type="text" className="form-control w-100" onChange={changeName} name="nombre" value={nombre} /></p>
	  			<button className="btn btn-small btn-success w-100" onClick={setIdAgregar}>Aceptar</button>
	  		</React.Fragment>
		);
}

export default NuevaCategoria;