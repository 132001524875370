import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Botones from './Botones'

const ModalComentarios = ({show,handleClose,comentario}) =>{

	return(
	    <Modal show={show} onHide={handleClose}
	    dialogClassName="modal-90w">
	        <Modal.Header closeButton>
	            <Modal.Title>Comentarios de refacturación</Modal.Title>
	        </Modal.Header>
	        <Modal.Body>
	        	<div className="container text-justify">
	        		{comentario}
	        	</div>
	        </Modal.Body>
	        <Modal.Footer>
	        	<Botones handleClose={handleClose}/>
	        </Modal.Footer>
	    </Modal>
	);
}

export default ModalComentarios;