import React, {useState,useEffect} from 'react';
import {insertData,updateItem} from '../../tools/Helper';

import FirmarModal from './FirmarModal';

const Firma = ({getLista,handleClose,show,titulo,reload,setReload,factura}) =>{

	const fecha = new Date();

	const [data, setData] = useState({
		id_factura: factura.id_factura,
		id_departamento: factura.id_departamento,
		id_estatus: factura.id_estatus,
		nombre: '',
		firma: '',
		no_productos: '',
	});

	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState("ok");
	const [message, setMessage] = useState("");
	
	const handleChange = e =>{
		setData({
			...data,
			[e.target.name]: e.target.value
		})
	}

	useEffect(() => { 
		setData({
			...data,
			id_factura: factura.id_factura,
			id_estatus: factura.id_estatus,
			id_departamento: factura.id_departamento,
			nombre: '',
			firma: '',
			no_productos: '',
		})
		
		if(reload === true){
			setStatus("ok");
			setReload(false);
		}
	}, [factura])

	const firmarData = async () => {
		setLoading(true);
		setMessage("");
		setStatus("ok");

		const firmar = await insertData("firmas",data);
		console.log(data);

		if(firmar.status === "error"){
			setMessage("Faltan campos por rellenar.");
			setStatus("error");
		}else if(firmar.status==="success"){
			const cambiar = await updateItem("cambiar-estatus/" + data.id_factura);
				if(cambiar.status === "success"){
				setStatus("success");
				setMessage("Se ha firmado la factura correctamente.");
				setReload(true);
			}
		}else{
			setMessage("Ha ocurrido un error, por favor intente otra vez.");
			setStatus("error");
		}    
		
		setLoading(false);
	};

	return (
		<React.Fragment>
			<FirmarModal 
				handleChange={handleChange} 
				loading={loading} 
				status={status} 
				message={message}
				show={show}
				handleClose={handleClose}
				fecha={fecha}
				id={factura.id_factura}
				firmarData={firmarData}
				titulo={titulo}
			/>
		</React.Fragment>
	);
}

export default Firma;