import React from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from '../../formComponents/InputGroup';

const Formulario = ({fecha, id, status, loading, lista, selectLista}) =>{

    if(status !== "success" && loading === false){
	    return(
	        <Form>
		        <Form.Group controlId="formFactura">
		            <Form.Label>Factura:</Form.Label>
		            <Form.Control type="text" placeholder={id} name="id_factura" value={id} readOnly />
		        </Form.Group>
		        <Form.Group controlId="formDatos">
		        </Form.Group>
		        <Form.Group contoldId="tipoEntrega">
		            <InputGroup label="Tipo de entrega" options={lista} onChange={selectLista} name="tipo_entrega" />
		        </Form.Group>
	        </Form>
	    );
    }
    return("");
}

export default Formulario;