import React from 'react';
import Button from 'react-bootstrap/Button';

const botones = ({status, loading, handleClose, firmarData}) =>{
    if(status !== "success" && loading === false){
      	return(
	        <React.Fragment>
		        <Button variant="secondary" onClick={handleClose}>
		        	Cancelar
		        </Button>
		        <Button variant="primary" onClick={firmarData}>
		        	Firmar
		        </Button>
	        </React.Fragment>
      	);
    }

    return(
      <Button variant="primary" onClick={handleClose}>
          Aceptar
      </Button>
    );
}

export default botones;