import React, {useState} from 'react';

function Botones({setId,id,nombre,usuario}) {

	const [datos, setDatos] = useState({nombre_completo: nombre, usuario: usuario, password: ''});

	const handleChange = e =>{
		setDatos({
			...datos,
			[e.target.name]: e.target.value
		})
	}

  	const[editando, setEditando] = useState(false);
  	const[eliminando, setEliminando] = useState(false);

	const editar = () =>{
		console.log("editar")
  		setEditando(!editando);
  	}

  	const eliminar = () =>{
		console.log("eliminar")
  		setEliminando(!eliminando);
  	}

  	const setIdEditar = (id,datos) =>{
  		setId([id,true,datos]);
  	}

  	const setIdEliminar = (id) =>{
  		setId([id,false]);
  	}

	if(editando === true){
		return (
			<React.Fragment>
				<p className="mb-0 pt-2">Nombre: <input type="text" className="form-control" onChange={handleChange} name="nombre_completo" value={datos.nombre_completo} /></p>
				<p className="mb-0 pt-2">Usuario: <input type="text" className="form-control" onChange={handleChange} name="usuario" value={datos.usuario} /></p>
				<p className="mb-0 pt-2">Contraseña: <input type="password" className="form-control" onChange={handleChange} name="password" value={datos.password} />
				<small>*Dejar en blanco si no desea modificar la contraseña.</small></p>
  			<button className="btn btn-small btn-success" onClick={()=>setIdEditar(id,datos)}>Aceptar</button> 
  			<button className="btn btn-small btn-secondary" onClick={()=>editar()}>Cancelar</button>
		  	</React.Fragment>);
	}else if(eliminando === true){
		return (
			<React.Fragment>
		  		<p className="mb-0 pt-2">¿Seguro que desea eliminar el usuario {usuario}?</p>
		  		<p><button className="btn btn-small btn-success" onClick={()=>setIdEliminar(id)}>Aceptar</button> 
		  		<button className="btn btn-small btn-secondary" onClick={eliminar}>Cancelar</button></p>
		  	</React.Fragment>);
	}else{
		return (
			<p><button className="btn btn-small btn-primary" onClick={editar}>Editar</button> 
			<button className="btn btn-small btn-danger" onClick={eliminar}>Eliminar</button></p>);
	}
}

export default Botones;