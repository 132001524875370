import React from 'react';
import DateTimePicker from 'react-datetime-picker';

function DatePicker({setFechaInicio,setFechaFin,fechaInicio,fechaFin}) {
  return (
    <div className="row py-3">
      <div className="col-md-4">
        <DateTimePicker
			onChange={setFechaInicio}
			value={fechaInicio}
			className="form-control c-padding"
			disableClock
		/>
		</div>
		<div className="col-md-4">
		<DateTimePicker
			onChange={setFechaFin}
			value={fechaFin}
			className="form-control c-padding"
			disableClock
		/>
      </div>
    </div>
  );
}

export default DatePicker;