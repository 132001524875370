import React from 'react';

function Input(props) {

	if(props.catalogoLoaded===true){

		var listItems;
		if(props.type===1){
			 listItems = (props.options).map((opcion) =>
			<option key={opcion.cliente} id={opcion.cliente}>{opcion.cliente}</option>);
		}else if(props.type===2){
			 listItems = (props.options).map((opcion) =>
			<option key={opcion.zona} id={opcion.zona}>{opcion.zona}</option>);
		}else if(props.type===3){
			 listItems = (props.options).map((opcion) =>
			<option key={opcion.vendedor} id={opcion.vendedor}>{opcion.vendedor}</option>);
		}

		return(
		    <div className={"col-md-"+props.colSize}>
			    <div className="form-group">
			        <label>{props.label}</label>
			        <select name={props.name} className="form-control" onChange={props.onChange}>
			        	<option key="todos" id="todos">Seleccionar todos</option>
			        	{listItems}
			        </select>
			    </div>
		    </div>
		);
	} else{
		return(
		<div className={"col-md-"+props.colSize}>
		    <div className="form-group">
		        <label>{props.label}</label>
		        <select name={props.name} className="form-control" disabled onChange={props.onChange}>
		        	<option key={1} id={1}>Cargando...</option>
		        </select>
		    </div>
		</div>
		);
	}
	
}

export default Input;