import React from "react";


const StatusMsg = props =>{

    if(props.loading === true){
      return(
        <div className="alert alert-primary" role="alert">
          Cargando...
        </div>
      )
    }
    
	return null
}

export default StatusMsg;