import React from 'react';
import ConsultaExcel from './ConsultaExcel';
import ReportePDF from './ReportePDF';

function Consultas2() {

	return (
		<div className="content">
			<div className="row">
				<div className="col-md-12 pt-2">
					
					<ConsultaExcel />
					<ReportePDF />

				</div>
			</div>
		</div>
	);
}

export default Consultas2;