import React from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const Formulario = ({fecha, id, handleChange, status, loading}) =>{

	const fechaNormal = fecha.getFullYear() + ((fecha.getMonth()+1) < 10 ? '-0' : '-') + (fecha.getMonth()+1) + 
	   (fecha.getDate() < 10 ? '-0' : '-') + fecha.getDate() +
	   (fecha.getHours() < 10 ? '  -  0' : '  -  ') + fecha.getHours() + 
	   (fecha.getMinutes() < 10 ? ':0' : ':') + fecha.getMinutes();

    if(status !== "success" && loading === false){
	    return(
	        <Form>
		        <Form.Group controlId="formFactura">
		            <Form.Label>Factura:</Form.Label>
		            <Form.Control type="text" placeholder={id} name="id_factura" value={id} readOnly />
		        </Form.Group>
		        <Form.Group controlId="formDatos">
		            <Row>
		              <Col>
		                <Form.Label>Nombre:</Form.Label>
		                <Form.Control placeholder="Nombre" name="nombre" onChange={handleChange} />
		              </Col>
		              <Col>
		                <Form.Label>Fecha:</Form.Label>
		                <Form.Control placeholder="Fecha" disabled value={fechaNormal} />
		              </Col>
		            </Row>
		        </Form.Group>
		        <Form.Group controlId="formFirma">
		            <Form.Label>Firma:</Form.Label>
		            <Form.Control type="text" name="firma" onChange={handleChange} />
		        </Form.Group>
		        <Form.Group controlId="formCantidad">
		            <Form.Label>Numero de productos:</Form.Label>
		            <Form.Control type="text" name="no_productos" onChange={handleChange} />
		        </Form.Group>
		        <Form.Group controlId="exampleForm.ControlTextarea1">
				    <Form.Label>Comentario</Form.Label>
				    <Form.Control as="textarea" rows="3" name="comentario" onChange={handleChange}/>
				</Form.Group>
	        </Form>
	    );
    }
    return("");
}

export default Formulario;