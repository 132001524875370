import React from 'react';

function Footer() {

	const fecha = new Date().getFullYear();

  return (
	  <footer className="footer footer-black  footer-white ">
	    <div className="container-fluid">
	      <div className="row">
	        <nav className="footer-nav">
	        </nav>
	        <div className="credits ml-auto">
	          <span className="copyright">
	            © 
	              {fecha}
	            , Golsystems
	          </span>
	        </div>
	      </div>
	    </div>
	  </footer>
  );
}

export default Footer;