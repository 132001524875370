import React from 'react';
import Button from 'react-bootstrap/Button';

const Botones = ({status, loading, handleClose}) =>{

  	return(
        <React.Fragment>
	        <Button variant="primary" onClick={handleClose}>
	        	Cerrar
	        </Button>
        </React.Fragment>
  	);

}

export default Botones;