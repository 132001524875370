import React from 'react';
import Button from 'react-bootstrap/Button';

const botones = ({status, loading, handleClose, firmarData}) =>{
    
  if(loading === false){
    if(status === "success"){
      return(
        <Button variant="primary" onClick={handleClose}>
            Aceptar
        </Button>
      );
    }else{
      return(
          <React.Fragment>
            <Button variant="secondary" onClick={handleClose}>
              Cancelar
            </Button>
            <Button variant="primary" onClick={firmarData}>
              Firmar
            </Button>
          </React.Fragment>
      );
    }

  }else{
    return ("");
  }

    /*if(status !== "success" && loading === false){
      	return(
	        <React.Fragment>
		        <Button variant="secondary" onClick={handleClose}>
		        	Cancelar
		        </Button>
		        <Button variant="primary" onClick={firmarData}>
		        	Firmar
		        </Button>
	        </React.Fragment>
      	);
    }else if(loading === true){

    }

    return(
      <Button variant="primary" onClick={handleClose}>
          Aceptar
      </Button>
    );*/
}

export default botones;