import React from 'react';

function Input(props) {

	if(props.options){
		const listItems = (props.options).map((opcion) =>
		<option key={opcion.id_estatus} value={opcion.id_estatus} id={opcion.id_estatus}>{opcion.descripcion}</option>);

		return(
		    <div className={"col-md-"+props.colSize}>
			    <div className="form-group">
			        <label>{props.label}</label>
			        <select name={props.name} className="form-control" onChange={props.onChange} value={props.value}>
			        	{listItems}
			        </select>
			    </div>
		    </div>
		);
	} else{
		return(
		<div className={"col-md-"+props.colSize}>
		    <div className="form-group">
		        <label>{props.label}</label>
		        <select name={props.name} className="form-control" disabled onChange={props.onChange}>
		        	<option key={1} id={1}>Cargando...</option>
		        </select>
		    </div>
		</div>
		);
	}
	
}

export default Input;