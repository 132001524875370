import React, {useState,useEffect} from 'react';

import DataTable from 'react-data-table-component';
import {customStyles, paginacionOpciones, Empty} from '../../tools/TableStyles';

import LoadingMsg from '../../tools/LoadingMsg';
import {getData} from '../../tools/Helper';

const Formulario = ({selectedChange}) =>{

	const [lista, setLista] = useState([]);
	const [loading, setLoading] = useState(false);

	const getLista = async() =>{
		setLista([]);
		setLoading(true);
		const data = await getData('facturas/' + 15);

		if(data.status==="success"){
			setLista(data.datos);
		}else{
			setLista([]);
		}
		setLoading(false);
	}

	useEffect(()=>{
		getLista();
	},[])

	const columnas = [
		{
			name: 'NO. REMISION',
			selector : 'no_remision',
		},
		{
			name: 'FOLIO',
			selector : 'folio_sat',
		},
		{
			name: 'PEDIDO',
			selector : 'numero_pedido',
		},
		{
			name: 'CLIENTE',
			selector : 'cliente',
		},
		{
			name: 'FECHA',
			selector : 'fecha_remision',
			wrap:true,
			grow:0.5,
			minWidth:"6rem"
		},
	]

	    return(
	    	<React.Fragment>
	    	<LoadingMsg loading={loading} />
	    	{ loading === false ? 
	    		<DataTable
				columns= {columnas}
				title = "Remisiones completadas"
				data={lista}
				pagination
				paginationComponentOptions = {paginacionOpciones}
				fixedHeader
				customStyles={customStyles}
				noDataComponent=<Empty />
				selectableRows
				selectableRowsNoSelectAll
				onSelectedRowsChange={selectedChange}
				/> : 
				"" }
			</React.Fragment>
	    );
}

export default Formulario;